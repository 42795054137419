import React, { useState } from 'react'
import { connect } from 'react-redux'
import { addToCart, signIn } from 'store/actions/userActions'
import { useMutation } from '@apollo/react-hooks'
import { TTempDesign } from 'types'

import { ButtonStyle as B, ModalLayout as M } from 'styles'
import { Error, setToken } from 'utils'
import ADD_TO_CART from 'gql/ADD_TO_CART'
import { CART, PRODUCTS } from 'routes/user'
import { Link, useHistory } from 'react-router-dom'
import { TProduct } from 'types/Product'
import { Button, Flex, Heading, Text } from '@sweaterplanet/nucleus-style'
import { withTheme } from 'styled-components'
import { ITheme } from 'types/Theme'
import { TUser } from '../../types/User'

export interface ICartButtonProps {
  product: TProduct
  addToCart: () => void

  setError: (error: string) => void
  tempDesigns: TTempDesign[]
  isStore?: boolean
  variantSelections: Record<
    string,
    Record<string, { quantity: number; customizations: { name: string }[] }>
  >[]
  theme: ITheme

  signIn: (user: TUser) => void
  storeId?: string
}

const Index: React.FC<ICartButtonProps> = props => {
  const {
    product,
    tempDesigns,
    setError,
    isStore,
    storeId,
    variantSelections,
    theme: { colors },
  } = props

  const history = useHistory()

  const [successVisible, setSuccessVisible] = useState(false)
  const [errorVisible, setErrorVisible] = useState(false)
  const [addToCart] = useMutation(ADD_TO_CART, {
    variables: {
      product,

      variantSelections,

      tempDesigns,
      isStore,
    },
  })
  const [previewUrl, setPreviewUrl] = useState('')

  const isVariantSelectionError = (
    variantSelections: Record<
      string,
      Record<string, { quantity: number; customizations: { name: string }[] }>
    >[]
  ) => {
    for (const colorWithSizes of variantSelections) {
      if (
        !Object.values(Object.values(colorWithSizes)[0]).every(
          (obj: { quantity: number; customizations: { name: string }[] }) =>
            obj.quantity + obj.customizations.length === 0
        ) &&
        !Object.values(Object.values(colorWithSizes)[0]).every(
          (obj: { quantity: number; customizations: { name: string }[] }) =>
            isNaN(obj.quantity)
        )
      )
        return false
    }
    return true
  }

  const handleAddToCart = async () => {
    if (variantSelections.length === 0) {
      setError('Missing a size')
    } else if (isVariantSelectionError(variantSelections)) {
      setError('Missing a quantity')
    } else {
      try {
        const status = await addToCart()
        if (status.data) {
          props.addToCart()
          // Guest Account
          setPreviewUrl(
            status.data.addToCart.cart.cartItems[
              status.data.addToCart.cart.cartItems.length - 1
            ].designs[0]?.file.id
          )
          if (status.data.addToCart.auth) {
            setToken(status.data.addToCart.auth.token)
            props.signIn(status.data.addToCart.auth.user)
          }
          setSuccessVisible(true)
        }
      } catch (error: any) {
        console.log(error)
        setErrorVisible(true)
        return <Error message={error.message} />
      }
    }
  }

  return (
    <>
      <Button
        bg="green"
        color="white"
        maxWidth
        onClick={() => handleAddToCart()}
      >
        Add To Cart
      </Button>

      {successVisible && (
        <M.Modal>
          <h2>Your design has successfully been added to the Cart</h2>
          <B.Exit onClick={() => setSuccessVisible(false)}>X</B.Exit>

          <Flex flexDirection="row" justifyContent="space-evenly">
            <Button
              bg="green"
              color="white"
              style={{ marginRight: '1rem' }}
              onClick={() =>
                storeId
                  ? history.push(`/store/${storeId}`)
                  : history.push(`${PRODUCTS}?previewID=${previewUrl}`)
              }
            >
              Continue
            </Button>
            <Link
              to={`${CART}${props.storeId ? '?store=' + props.storeId : ''}`}
              style={{ marginLeft: '1rem' }}
            >
              <Button bg="green" color="white">
                Cart
              </Button>
            </Link>
          </Flex>
        </M.Modal>
      )}
      {errorVisible && (
        <M.Modal>
          <Heading element="h4" color={colors.text.default}>
            Something went wrong...
          </Heading>
          <B.Exit onClick={() => setErrorVisible(false)}>X</B.Exit>
          <Text color={colors.text.default}>Please try again later</Text>
        </M.Modal>
      )}
    </>
  )
}

const mapStateToProps = () => ({})

const mapActionsToProps = {
  signIn,
  addToCart,
}

export default connect(mapStateToProps, mapActionsToProps)(withTheme(Index))
